@use "../fonts/Fonts.module" as *;

.outlined1 {
  border-radius: 5px;
  cursor: pointer;
  outline: 2px solid transparent;
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 26px;
  display: flex;

  &.iconOnly {
    padding: 8px;
  }

  &:disabled, {
    pointer-events: none;
  }

  &.primary {
    color: #2D81E0;
    background-color: #FFF;
    border: 1px solid #2D81E0;

    & svg path {
      fill: #2D81E0;
    }

    &:hover {
      color: #3C94F8;
      border-color: #469EFF;

      & svg path {
        fill: #3C94F8;
      }
    }

    &:disabled {
      color: #B4D1F2;
      border-color: #B4D1F2;

      & svg path {
        fill: #B4D1F2;
      }
    }

    &:focus {
      color: #2D81E0;
      border: 2px solid #314A8B;
      padding: 7px 25px;

      &.iconOnly {
        padding: 7px;
      }
    }

    &:active {
      color: #276FC1;
      border-color: #276FC1;

      & svg path {
        fill: #276FC1;
      }
    }
  }
}

.filled1 {
  border-radius: 5px;
  cursor: pointer;
  outline: 2px solid transparent;
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 8px 26px;

  &.iconOnly {
    padding: 8px;
  }

  &:disabled, {
    pointer-events: none;
  }

  &.primary {
    color: #FFF;
    background-color: #2D81E0;
    border: 2px solid transparent;

    &:hover {
      background-color: #469EFF;
    }

    &:disabled {
      background-color: #B4D1F2;
    }

    &:focus {
      border: 2px solid #314A8B;
      background-color: #2D81E0;
    }

    &:active {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.17) inset;
      background-color: #2D81E0;
    }
  }

  &.secondary {
    color: #2D81E0;
    background-color: #F2F4F5;
    border: 2px solid transparent;

    &:hover {
      background-color: #E8EAEB;
    }

    &:disabled {
      color: #B4D1F2;
      background-color: #F2F4F5;
    }

    &:focus {
      border: 2px solid #314A8B;
      background-color: #F2F4F5;
    }

    &:active {
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.07) inset;
      background-color: #F2F4F5;
    }
  }
}

a.outlined1 {
  text-decoration: none;
}

.text1 {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 10px;
  background-color: transparent;
  border: 0;

  &.primary {
    color: #2D81E0;

    &:hover {
      color: #469EFF;
      text-decoration: underline;
    }

    &:disabled {
      color: #B4D1F2;
    }
  }

  &.error {
    color: #E64646;

    &:hover {
      color: #ff5e5e;
      text-decoration: underline;
    }

    &:disabled {
      color: #e48686;
    }
  }
}