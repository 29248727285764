@use "../../../../../../sqadmin/core/presentation/constants/dimens" as *;

.root {
  width: $formWidth;

  & .field {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .list {
    margin-top: 8px;
  }

  .error {
    margin-top: 5px;
  }
}
