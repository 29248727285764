.root {
  width: 100%;

  & .selectedItemsContainer {
    padding-top: 6px;
    padding-bottom: 2px;
    display: flex;
    flex-wrap: wrap;

    & .selectedItem {
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 8px 12px;
      background-color: #F2F4F5;
      border-radius: 5px;
      border: 1px solid transparent;
      display: flex;
      align-items: center;

      &:not(.disabled):hover {
        border: 1px solid #2D81E0;

        & svg:hover {
          cursor: pointer;

          path {
            fill: #2D81E0;
          }
        }
      }

      & svg {
        margin-left: 4px;
      }
    }
  }

  & .loadingIndicator {
    margin-top: 8px;
  }
}
