@use "../fonts/Fonts.module" as *;

.headline1 {
  font-family: $fontFamily;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.title1 {
  font-family: $fontFamily;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.title2 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.label1 {
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #6D7885;
  }

  &.secondary {
    color: #253247;
  }
}

.table1 {
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.table2 {
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.table3 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #253247;
  }

  &.secondary {
    color: #2D81E0;
  }
}

.checkbox1 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #253247;
  }
}

.error1 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #E64646;
  }
}

.body1 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #253247;
  }

  &.secondary {
    color: #BAC0C5
  }
}

.body2 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.primary {
    color: #253247;
  }

  &.secondary {
    color: #2D81E0;
  }
}