@use '../../constants/dimens' as *;

.root {
  & button {
    padding-left: 0;
    margin-top: 4px;
  }

  .imagesContainer {
    display: flex;
    gap: 16px
  }

  .imageTitle {
    margin-bottom: 5px;
  }

  .imageContainer {
    position: relative;
    width: $imagePreviewSize;
    height: $imagePreviewSize;
    color: #253247;
    background-color: #F7F9FC;
    caret-color: #2D81E0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &:hover {
      border-color: #2D81E0;
    }

    &:active {
      border-color: #2D81E0;
    }

    &:disabled {
      color: #BAC0C5;
      background-color: #EBEEF1;
      pointer-events: none;
    }

    &:focus {
      border: 2px solid #314A8B;
      padding: 11px 7px;
    }

    & .imageLoader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    & .imageChooseButton {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      top: 0;
      align-items: center;
      justify-content: center;
    }

    input[type="file"] {
      display: none;
    }
  }

  & .error {
    margin-top: 4px;
  }
}
