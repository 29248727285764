$closeButtonBottomMargin: 32px;
$closeButtonSize: 44px;
$modalMargin: 32px;

.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: default;
}

.background {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  width: 100%;
  min-height: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  z-index: 1;
}

.closeIcon {
  width: 28px;
  height: 28px;
  background-image: url("../../../../../../core/assets/icons/ic_close.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.closeButton {
  width: $closeButtonSize;
  height: $closeButtonSize;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: 32px;
  cursor: pointer;
}

.closeButtonContainer {
  z-index: 2;
  margin-top: 32px;
  position: fixed;
}
