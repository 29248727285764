.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  cursor: default;
  overflow: auto;
}

.background {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  width: 100%;
  min-height: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 1;
  position: relative;
  min-height: 100%;
}

.content {
  min-height: 100%;
}