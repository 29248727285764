@use "../../../../../core/presentation/constants/dimens" as *;

.pageHeader {
  position: fixed;
  width: calc(100% - $sideMenuWidth);
  background-color: #fff;
  z-index: 1;

  & .row {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
  }

  & h1 {
    flex: 1 1 auto;
  }
}

.root {
  overflow: auto;
  padding: 84px 20px 100px;

  & .loading {
    width: $formWidth;
  }

  & .error {
    width: $formWidth;
  }

  .additionalForm {
    pointer-events: auto;
    background: #fff;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .additionalFormContent {
    padding: 16px;
  }

  .additionalFormContentWidthLevel0 {
    width: calc(100vw - $sideMenuWidth - 200px);
  }

  .additionalFormContentWidthLevel1 {
    width: calc(100vw - $sideMenuWidth - 2 * 200px);
  }

  .additionalFormFooter {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #E0E3E7;
    padding: 20px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - $sideMenuWidth);
  background-color: #fff;
  border-top: 1px solid #E0E3E7;
  padding: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
}
