@use "../fonts/Fonts.module" as *;

.errorMessageContainer {
  align-items: center;
  border: 1px solid #d9dbde;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  gap: 4px;
  justify-content: center;
  padding: 10px 16px;

  &:hover {
    background-color: rgba(45, 129, 224, 0.1);
  }
}