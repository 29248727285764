@use "../../../../../../sqadmin/core/presentation/constants/dimens" as *;

.root {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  & .field {
    width: 290px;
  }
}