@use "../../../../design/fonts/Fonts.module" as *;

.root {
  display: flex;
  width: 100%;

  & .selectButtonContainer {
    flex: 1 1 auto;
  }

  & .clearButton {
    flex: 0 0 auto;
    margin-left: 8px;
  }

  & .loadingIndicator {
    margin-top: 8px;
  }
}
