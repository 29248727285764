.root {
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background-color: #F7F9FC;

    div {
      color: #2D81E0;
    }
  }

  & > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
  }

  &.selected {
    div {
      color: #2D81E0;
    }
  }
}