.searchBar {
  padding: 2px 10px;
}

.hasMoreObjects {
  padding: 8px;

  & .hasMoreButton {
    cursor: pointer;
  }
}
