.root {
  z-index: 1;
  overflow: auto;

  &.noScroll {
    position: absolute;
  }

  &.hasScroll {
    position: fixed;
  }
}
