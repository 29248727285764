.root {

  & .header {
    display: flex;
    align-items: center;
    width: 100%;

    .title {
      flex: 1 1 auto;
    }

    & button {
      padding-right: 0;
    }
  }

  & .field {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    & .title {
      margin: 8px 0;
    }

    & .input {
      display: flex;
      flex-direction: column;
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .listHeader {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-top: 15px;
      width: 100%;

      & button {
        padding-right: 0;
      }
    }

    .title {
      flex: 1 1 auto;
    }

    &.nested {
      margin-left: 20px;

      &:first-child {
        margin-top: 11px;
      }
    }

    &.grid {
      display: flex;
      flex-wrap: wrap;
      column-gap: 80px;
      row-gap: 20px;
      flex-direction: inherit;

      .listItem {
        min-width: 200px;
      }
    }

    &.inline {
      :not(:first-child).listItem {
        margin-top: 15px;
      }
    }
  }
}
