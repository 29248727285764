.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;

  & .leftContainer {
    gap: 16px;
    display: flex;
    align-items: center;
  }

  & .rightContainer {
    gap: 16px;
    display: flex;
  }
}

