$checkboxSide: 24px;

.checkboxContainer {
  display: flex;
}

.checkbox {
  display: none;
}

.checkbox + label {
  min-height: $checkboxSide;
}

.checkbox + label > div {
  margin-left: $checkboxSide;
  min-height: $checkboxSide;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  padding-left: 8px;
  -webkit-user-select: none;
  user-select: none;
}

.checkbox + label::before {
  content: " ";
  position: absolute;

  width: $checkboxSide;
  height: $checkboxSide;
  background-image: url("../../core/assets/icons/ic_checkbox_unchecked.svg");
}

.checkbox:checked + label::before {
  background: url("../../core/assets/icons/ic_checkbox_checked.svg");
}