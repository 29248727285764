@use "../fonts/Fonts.module" as *;

.textInput1 {
  position: relative;
  width: 100%;

  & input {
    font-family: $fontFamily;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12px 8px;
    width: 100%;
    border-radius: 5px;
    outline: 2px solid transparent;
  }

  &.primary {

    &:hover {
      & input {
        border-color: #2D81E0;

        &:disabled {
          border: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
    }

    & input {
      color: #253247;
      background-color: #F7F9FC;
      caret-color: #2D81E0;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &::placeholder {
        color: #9FAAB7;
      }

      &:hover {
        border-color: #2D81E0;
      }

      &:active {
        border-color: #2D81E0;
      }

      &:disabled {
        color: #BAC0C5;
        background-color: #EBEEF1;
        pointer-events: none;
      }

      &:focus {
        border: 2px solid #314A8B;
        padding: 11px 7px;
      }
    }

    &.error {
      & input {
        border-color: #E64646 !important;
        background-color: #FAEBEB;
      }
    }
  }

  .withLeftIcon {
    padding-left: 40px;

    &:focus {
      padding-left: 39px !important;
    }
  }

  .withRightIcon {
    padding-right: 40px;

    &:focus {
      padding-right: 39px !important;
    }
  }
}

.textInputIcon {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  padding: 0 8px;

  &.left {
    left: 0
  }

  &.right {
    right: 0;
  }
}

.cursorPointer {
  &:hover {
    cursor: pointer;
  }

  & input:hover {
    cursor: pointer;
  }
}