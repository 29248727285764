.root {
  display: flex;

  .positionController {
    padding: 0 5px;

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      opacity: 0.5;

      &:hover {
        cursor: inherit;
      }
    }
  }
}