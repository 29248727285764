.root {
  background-color: #F5F6F9;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  & .formContainer {
    background-color: #fff;
    border: 1px solid #D9DBDE;
    border-radius: 10px;
    margin: 0 auto;
    max-width: 500px;
    min-width: 389px;
    padding: 20px;
  }

  & .formFields {
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column;

    & .formRow {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      width: 100%;

      & .title {
        margin-bottom: 4px;
        margin-left: 2px;
      }
    }
  }

  & .formHeader {
    align-items: center;
    display: flex;
    flex-direction: column;

    & img {
      margin-bottom: 15px;
    }

    & h1 {
      margin-bottom: 15px;
    }
  }

  & .error {
    margin-top: 5px;
  }
}