@use '../../../../../core/presentation/constants/dimens' as *;
@use "../../../../../design/fonts/Fonts.module" as *;

.tableContainer {
  width: 100%;
  display: flex;

  & .table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 20px 9px;
    flex: 1 1 auto;

    & th {
      padding: 12px 20px;
    }

    & td {
      padding: 10px 20px;
    }

    & th,
    & td {
      border-bottom: 1px solid #D9DBDE;
      border-left: 1px solid #D9DBDE;
      position: relative;

      &:last-child {
        border-right: 1px solid #D9DBDE;
      }

      & .rowLink {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
      }

      & .cellLink {
        position: relative;
        z-index: 2;
        text-decoration-color: #253247;

        &:hover {
          text-decoration-color: #469EFF;
          color: #469EFF;
        }

        & div:hover {
          color: #469EFF;
        }
      }
    }

    & tr:hover {
      background-color: rgba(45, 129, 224, 0.1);
    }

    & th {
      background-color: #F2F4F7;
    }

    & thead th {
      border-top: 1px solid #D9DBDE;
    }

    & thead tr {
      z-index: 3;
      position: sticky;
    }

    & .clickable {
      cursor: pointer;
    }

    & thead th:first-child {
      border-top-left-radius: 4px;
    }

    & thead th:last-child {
      border-top-right-radius: 4px;
    }
  }
}

.listPageHeader {
  width: calc(100% - $sideMenuWidth);
  z-index: 4;
  top: 0;
  background-color: white;
  position: fixed;

  & .row {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 20px;
  }

  & h1 {
    flex: 1 1;
  }

  .searchBarContainer {
    max-width: 400px;
    flex: 1 1;
  }
}

.paginateComponent {
  border: 0 !important;
  padding: 0 !important;
}

.sorting {
  width: 24px;
  height: 24px;
}

.sorting.asc {
  background-image: url("../../../../../core/assets/icons/ic_arrow_up.svg");
}

.sorting.desc {
  background-image: url("../../../../../core/assets/icons/ic_arrow_down.svg");
}

.thContent {
  display: flex;
  align-items: center;
}
