$fontFamily: "Inter";

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/Inter/regular/Inter-Regular.eot");
  src: url("../assets/fonts/Inter/regular/Inter-Regular.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Inter/regular/Inter-Regular.woff2") format("woff2"),
  url("../assets/fonts/Inter/regular/Inter-Regular.woff") format("woff"),
  url("../assets/fonts/Inter/regular/Inter-Regular.ttf") format("truetype"),
  url("../assets/fonts/Inter/regular/Inter-Regular.svg#Inter-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/Inter/medium/Inter-Medium.eot");
  src: url("../assets/fonts/Inter/medium/Inter-Medium.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Inter/medium/Inter-Medium.woff2") format("woff2"),
  url("../assets/fonts/Inter/medium/Inter-Medium.woff") format("woff"),
  url("../assets/fonts/Inter/medium/Inter-Medium.ttf") format("truetype"),
  url("../assets/fonts/Inter/medium/Inter-Medium.svg#Inter-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.eot");
  src: url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.woff") format("woff"),
  url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.ttf") format("truetype"),
  url("../assets/fonts/Inter/semi-bold/Inter-SemiBold.svg#Inter-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: $fontFamily;
  src: url("../assets/fonts/Inter/bold/Inter-Bold.eot");
  src: url("../assets/fonts/Inter/bold/Inter-Bold.eot?#iefix") format("embedded-opentype"),
  url("../assets/fonts/Inter/bold/Inter-Bold.woff2") format("woff2"),
  url("../assets/fonts/Inter/bold/Inter-Bold.woff") format("woff"),
  url("../assets/fonts/Inter/bold/Inter-Bold.ttf") format("truetype"),
  url("../assets/fonts/Inter/bold/Inter-Bold.svg#Inter-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
