.calendar {
  background-color: white;

  & table {
    border-collapse: collapse;

    & td,
    & th {
      padding: 0;
    }
  }
}

.header {
  display: flex;
  align-items: center;

  & .changeMonthButton {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    cursor: pointer;

    &.next {
      background-image: url("../../core/assets/icons/ic_arrow_right.svg");
    }

    &.previous {
      background-image: url("../../core/assets/icons/ic_arrow_left.svg");
    }

    &:hover {
      background-color: #dddddd;
    }
  }

  & .monthName {
    flex: 1 1 auto;
    text-align: center;
  }
}

.day,
.weekDay {
  width: 42px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day {
  cursor: pointer;
  border-radius: 4px;
  padding: 8px;
  flex-direction: column;
  border: 1px solid white;

  &.range {
    background-color: #f3dfc6;
    border: 1px solid #f3dfc6;
    border-radius: 0;

    &.dateFrom {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.dateTo {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &:hover {
    background-color: #f3dfc6;
    border: 1px solid #f09539;
  }

  &.current {
    font-weight: bold;
    color: #cc7e2f;
  }

  &.otherMonth {
    color: #aaaaaa;
  }

  &.hidden {
    visibility: hidden;
  }

  &.selected {
    background-color: #f09539;
    border: 1px solid #f09539;
    color: white;

    &:hover {
      background-color: #cc7e2f;
      border: 1px solid #cc7e2f;
    }

    & .dayDescription {
      color: white;
    }
  }

  &.dateFrom:not(.dateTo) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.dateTo:not(.dateFrom) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  & .dayName {
    width: 100%;
    text-align: center;
  }

  & .dayDescription {
    color: #aaaaaa;
    font-size: 0.8em;
    min-height: 0.8em;
  }
}
