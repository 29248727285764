.root {
  position: relative;
  width: 100%;
}

.inputsContainer {
  width: 100%;
  display: flex;
}

.inputContainer {
  border: 1px solid #000000;
  width: 100%;
  display: flex;
}

.input {
  border: none;
  width: 100%;
}

.calendarIcon {
  width: 24px;
  height: 24px;
}

.calendarIcon.inactive {
  background-image: url("../../core/assets/icons/ic_calendar.svg");
}

.calendarIcon.active {
  background-image: url("../../core/assets/icons/ic_calendar_active.svg");
}

.values {
  display: flex;
  cursor: pointer;
}

.clearButton {
  margin-left: 8px;
}
