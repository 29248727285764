@use '../../../../../core/presentation/constants/dimens' as *;
@use '../../../../../design/fonts/Fonts.module' as *;

.sideMenu {
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: $sideMenuWidth;
  background-color: #F5F6F9;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #E0E3E7;

  & .header {
    padding: 10px 20px;
    border-bottom: 1px solid #E0E3E7;
  }

  & .menuItems {
    flex: 1 1 auto;
    padding: 10px 0 0;

    & ul {
      padding: 0;
      margin: 0;
      width: 100%;

      & li {
        list-style-type: none;
        display: flex;
        width: 100%;

        & a {
          padding: 10px 20px;
          width: 100%;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.07px;
          color: #77828F;
        }

        & a,
        & a:visited,
        & a:hover,
        & a:active {
          text-decoration: none;
          color: #77828F;
        }

        & a:hover {
          color: #2D81E0;
        }

        & a.active {
          color: #2D81E0;
          background-color: rgba(45, 129, 224, .1);
        }
      }
    }
  }

  & .footer {
    flex: 0 0 auto;
    padding: 10px 0;
    border-top: 1px solid #E0E3E7;

    & ul {
      padding: 0;
      margin: 0;
      width: 100%;

      & li {
        list-style-type: none;
        display: flex;
        width: 100%;

        & a {
          align-items: center;
          display: flex;
          flex-direction: row;
          cursor: pointer;
          padding: 6px 14px;
          width: 100%;
          font-family: $fontFamily;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.07px;
          color: #77828F;

          & div {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          & svg {
            display: block;
            margin-right: 4px;
          }
        }

        & a,
        & a:visited,
        & a:hover,
        & a:active {
          text-decoration: none;
          color: #77828F;
        }

        & a:hover {
          color: #2D81E0;

          & svg path {
            fill: #2D81E0;
          }
        }

        & a.active {
          color: #2D81E0;
          background-color: rgba(45, 129, 224, .1);
        }
      }
    }
  }
}

.content {
  margin-left: $sideMenuWidth;
  height: 100%;
}

.page {
  min-height: 100%;
  position: relative;
}
